import { devtools, subscribeWithSelector } from 'zustand/middleware';
// This createWithEqualityFn was introduced in Zustand 5 as a backwards compatability for the create function.
// It is targeted at selectors that return new references.
// Changes made in Zustand 5 to the create function require that selectors return stable references.
// This broke the previous implementation of the CarFinder store.
// Here we implement the createWithEqualityFn, which allows the CarFinder store to maintain the same API as previous versions of Zustand.
// https://zustand.docs.pmnd.rs/migrations/migrating-to-v5#requiring-stable-selector-outputs
import { createWithEqualityFn } from 'zustand/traditional';

import '@/types/CarFinder';

import { createCarFinderSlice } from '@/store/slices/createCarFinderSlice';
import { type CarFinderSlice } from '@/store/typings';

/**
 * Creates a Zustand store for the CarFinder feature.
 *
 * It contains the state for the filters. It also contains the dispatch function that updates filter state in response to user actions.
 * It also contains the initializeFilters function, which is used to set the initial filter state when the component first loads.
 *
 * Accessible Values:
 * - filters: The state for the filters
 * - dispatch: Function for updating filter state in response to user actions
 * - initializeFilters: Function for setting initial filter state on component load
 */
export const useCarFinderStore = createWithEqualityFn<CarFinderSlice>()(
  devtools(
    subscribeWithSelector((...a) => ({
      ...createCarFinderSlice(...a),
    })),
  ),
);
