import {
  CarFinderConstants,
  CarFinderSortValue,
  FilterActionType,
} from '@/constants/carFinder';

import {
  FuelType as GraphQLFuelType,
  TransmissionType,
  type VehicleBodyType,
} from '@/lib/gql/graphql';

const { QueryParameter } = CarFinderConstants;

// Vehicle ranking class
export interface VehicleRankingClass {
  overallScore: number | null;
  fuelEconomy: number | null;
  techInnovation: number | null;
  vrPerformance: number | null;
  vrValue: number | null;
  rankInSubclassText: string | null;
}

// Update Car interface to match GraphQL response
export interface Car {
  make: string;
  model: string;
  year: number;
  url: string;
  price: number;
  featuredImage: string;
  bodyStyle: string;
  overallScore: number;
  rankInSubclassText: string | null;
  bodyTypes: string[];
  topRankingTrophyImage?: string;
}

export type SearchParams = Partial<
  Record<(typeof QueryParameter)[keyof typeof QueryParameter], string>
>;

// Type for the entire array of cars
export type CarFinderProps = Car[];

export type FuelType = Extract<
  GraphQLFuelType,
  | GraphQLFuelType.Diesel
  | GraphQLFuelType.Electric
  | GraphQLFuelType.Hydrogen
  | GraphQLFuelType.PremiumUnleaded
  | GraphQLFuelType.Unleaded
>;

// New type for all possible filter keys, including nested ones
export type FilterKey =
  | keyof FilterState
  | typeof QueryParameter.MinPrice
  | typeof QueryParameter.MaxPrice
  | typeof QueryParameter.MinYearParameter
  | typeof QueryParameter.MaxYearParameter;

export interface FilterState {
  make: string;
  model: string;
  seating_capacity: number;
  pricing: {
    min_price: number;
    max_price: number;
  };
  body_style: VehicleBodyType[];
  transmission_type: TransmissionType | '';
  year_range: {
    min_year: number;
    max_year: number;
  };
  horsepower: number;
  fuel_type: FuelType[];
  combined_mpg: number;
  adaptive_cruise_control: boolean;
  panoramic_roof: boolean;
  android_auto: boolean;
  apple_carplay: boolean;
  motor_trend_score: boolean;
}

export type QueryStringParameters = FilterState;

export type FilterAction =
  | { type: FilterActionType.SetMotorTrendScore; payload: boolean }
  | { type: FilterActionType.CancelMotorTrendScore }
  | { type: FilterActionType.SetAdaptiveCruiseControl; payload: boolean }
  | { type: FilterActionType.SetAndroidAuto; payload: boolean }
  | { type: FilterActionType.SetAppleCarplay; payload: boolean }
  | { type: FilterActionType.SetBodyStyle; payload: string }
  | { type: FilterActionType.SetCombinedMpg; payload: number }
  | { type: FilterActionType.SetFuelType; payload: string }
  | { type: FilterActionType.SetHorsepower; payload: number }
  | { type: FilterActionType.SetMake; payload: string }
  | { type: FilterActionType.SetModel; payload: string }
  | { type: FilterActionType.SetPanoramicRoof; payload: boolean }
  | {
      type: FilterActionType.SetPricing;
      payload: { min_price: number; max_price: number };
    }
  | { type: FilterActionType.SetTransmissionType; payload: string }
  | {
      type: FilterActionType.SetYearRange;
      payload: { min_year: number; max_year: number };
    }
  | {
      type: FilterActionType.SetSeatingCapacity;
      payload: number;
    }
  | { type: FilterActionType.ResetAll }
  | { type: FilterActionType.ResetFilter; payload: keyof FilterState };

export type SortOption =
  | CarFinderSortValue.PriceLow
  | CarFinderSortValue.PriceHigh
  | CarFinderSortValue.NameAsc
  | CarFinderSortValue.NameDesc;

export interface FiltersSliderStates {
  horsepower: number;
  fuelEconomy: number;
  maxPrice: number;
  minPrice: number;
  seatingCapacity: number;
  setSeatingCapacity: (number: number) => void;
  setHorsepower: (number: number) => void;
  setFuelEconomy: (number: number) => void;
  setMaxPrice: (number: number) => void;
  setMinPrice: (number: number) => void;
}

export interface ActiveFilter {
  label: string;
  onRemove: () => void;
}
