import { createSelector } from 'reselect';

import { GeoLocationStateKey as StateKey } from '@/store/constants';
import { type GeoLocationSlice as State } from '@/store/typings';

export const geoLocationSelector = (state: State) => state[StateKey];

export const zipCodeSelector = createSelector(
  geoLocationSelector,
  (state) => state.zipCode,
);

export const countryIsoCodeSelector = createSelector(
  geoLocationSelector,
  (state) => state.countryIsoCode,
);

export const ipAddressSelector = createSelector(
  geoLocationSelector,
  (state) => state.clientIp,
);
