import { useEffect, useRef, type FunctionComponent } from 'react';
import {
  getSourceEmbedder,
  type Embedder,
} from '@source-digital/embed-client-loader';

import { type SourceDigitalWrapperProps } from './SourceDigitalWrapper.props';

const SourceDigitalWrapper: FunctionComponent<SourceDigitalWrapperProps> = ({
  isFullscreen,
  isSticky,
  videoId,
  videoRef,
}) => {
  const embedderRef = useRef<Embedder | null>(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (embedderRef.current || !videoRef.current || !bottomRef.current) {
      // If we've already set up the embedder, do nothing
      // If we don't have a video ref or bottom ref yet, do nothing
      return;
    }
    getSourceEmbedder().then(async (SourceEmbedder) => {
      try {
        embedderRef.current = SourceEmbedder.register({
          clientId: videoId,
          direct: {
            layout: {
              initOptions: {
                bottom: {
                  el: bottomRef.current,
                },
              },
            },
          },
          el: videoRef.current,
          organizationId: '22',
        });
        await embedderRef.current?.embed();
      } catch (err) {
        console.error({ err });
      }
    });

    return () => {
      embedderRef.current?.destroy();
    };
  }, [videoRef, bottomRef, embedderRef, videoId]);

  useEffect(() => {
    if (!embedderRef.current?.context) {
      return;
    }
    if (isSticky || isFullscreen) {
      embedderRef.current.context.hide();
    } else {
      embedderRef.current.context.show();
    }
  }, [isFullscreen, isSticky, embedderRef]);

  return <div ref={bottomRef} />;
};

export default SourceDigitalWrapper;
